import React, { useState } from 'react';
import './Footer.css';
import { FiPhoneCall } from "react-icons/fi";
import footerLogo from './images/logo.png';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { IoLogoWechat } from "react-icons/io5";

const Footer = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);
    return (
        <>
            <div className="Footer">
                <div className='footer-logo-container'>
                    <Link to='/' className="footer-logo" onClick={closeMenu}>
                        <img id='logo-footer' src={footerLogo} />
                    </Link>
                </div>
                <div className='footer-content-container'>
                    <div className='footer-links'>
                        <ul>
                            <li className="nav-item">
                                <a className="" href="/inventory">ALL INVENTORY</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/requestCar">Car Finder</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-schedule'>
                        <h4>STORE HOURS</h4>
                        <p>Mon - Fri: 9:00AM - 6:00PM</p>
                        <p>Sat: 10:00AM - 5:00PM</p>
                        <p>Sun: CLOSED</p>
                    </div>
                    <div className='footer-location'>
                        <h2>Reach Us Here</h2>
                        <p><FiPhoneCall /> +1 646-509-6261</p>
                        <p><IoLogoWechat /> CommMotor</p>
                    </div>
                    <div className='footer-description'>
                        <h3>CommonWealth Motor</h3>
                        <p>Located in New Jersey and proudly serving the New York, Pennsylvania, Boston, and Delaware areas. At Commonwealth Motor, we bring fine cars to discerning drivers. For more information, visit our contact page. Follow us on social media for the latest updates and exclusive offers.</p>
                        FOLLOW US ON <SocialIcon url="https://www.instagram.com/commonwealthmotornj/" bgColor="black" style={{ height: 50, width: 50 }} className='social-icons' target="_blank"/>
                        <SocialIcon url="https://www.xiaohongshu.com/user/profile/61bea4c8000000001000700e" style={{ height: 50, width: 50 }} className='social-icons' target="_blank"/>
                    </div>
                    </div>
                </div>
            <div className='Last-footer'>
                <p>© 2025 Commonwealth Motor. All Rights Reserved.</p>
            </div>
        </>
    )
}

export default Footer